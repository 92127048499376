@import "@/styles/globals.scss";
@font-face {
  font-family: 'JeanLuc';
  src: url('fonts/jeanlucweb-thin_broad.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'JeanLuc';
  src: url('fonts/jeanlucweb-bold_broad.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}
